import { KeyedProps } from '@guider-global/shared-types';
import {
  ContentModal,
  KeyStat,
  KeyStatProps,
  Leaderboard,
  LeaderboardRow,
  ProgressIndicatorDial,
  Stack,
  Text,
} from '@guider-global/ui';
import { Box } from '@mui/material';

export type ProgressLeaderboardModalViewProps = {
  open: boolean;
  heading: string | undefined;
  yourContributionsLabel: string | undefined;
  onClose?: () => void;
  dialSize?: number;
  dialMetricLabel: string;
  dialMetricDescription: string | undefined;
  keyStats: KeyedProps<KeyStatProps>[];
  leaderboardRows: LeaderboardRow[];
  selectedLeaderboardRow?: number;
  rankColumnLabel: string | undefined;
  profileColumnLabel: string | undefined;
  contributionsColumnLabel: string | undefined;
};

export function ProgressLeaderboardModalView({
  open,
  heading,
  yourContributionsLabel,
  onClose,
  dialSize = 12,
  dialMetricLabel,
  dialMetricDescription,
  keyStats,
  leaderboardRows,
  selectedLeaderboardRow,
  rankColumnLabel,
  profileColumnLabel,
  contributionsColumnLabel,
}: ProgressLeaderboardModalViewProps) {
  return (
    <ContentModal
      paperSx={{
        minWidth: { md: 'fit-content' },
        height: { md: 'fit-content' },
      }}
      bodySx={{
        p: 0,
        pt: 2,
      }}
      open={open}
      heading={heading}
      onClose={onClose}
    >
      <Stack direction="column" sx={{ width: { xs: '100%', md: 'initial' } }}>
        <Text
          text={yourContributionsLabel}
          px={2}
          sx={{
            fontWeight: 500,
            pr: 2,
            pl: { xs: 2, md: 3 },
            pb: { xs: 2, md: 1 },
          }}
        />
        <Stack
          px={2}
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={{
            xs: 0,
            md: 3,
          }}
          sx={{ width: { xs: '100%', md: 'initial' } }}
          alignItems="flex-start"
        >
          <ProgressIndicatorDial
            percentComplete={100}
            size={dialSize}
            metricLabel={dialMetricLabel}
            metricDescription={dialMetricDescription}
            containerSx={{
              order: {
                xs: 1,
                md: 0,
              },
            }}
          />
          <Stack direction="row" justifyContent="space-between" sx={{ pr: 2 }}>
            {keyStats.map((stat) => (
              <KeyStat
                key={stat.key}
                align="center"
                divider={false}
                value={stat.value}
                label={stat.label}
              />
            ))}
          </Stack>
        </Stack>
        <Box px={0} py={1}>
          <Leaderboard
            header={{
              rankColumnLabel,
              profileColumnLabel,
              contributionsColumnLabel,
            }}
            rows={leaderboardRows}
            rowsPerPage={6}
            showNavigator={true}
            selected={selectedLeaderboardRow}
          />
        </Box>
      </Stack>
    </ContentModal>
  );
}
