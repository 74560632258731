import {
  Stack,
  Text,
  Image,
  ButtonStack,
  ButtonStackProps,
} from '@guider-global/ui';

interface NoMatchesViewProps {
  imageSrc: string | undefined;
  buttons: ButtonStackProps['buttons'];
  title: string | undefined;
  description: string | undefined;
}

export function NoMatchesView({
  imageSrc,
  buttons,
  title,
  description,
}: NoMatchesViewProps) {
  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      width={{ xs: '90%', md: '448px' }}
      spacing={0}
      mt={3}
    >
      <Text variant="h2" text={title} mb={3} />
      {imageSrc && <Image src={imageSrc} fit="contain" width={'80%'} />}
      <Text textAlign={'center'} variant="subtitle1" text={description} />
      <ButtonStack width={'100%'} direction={'row'} mt={6} buttons={buttons} />
    </Stack>
  );
}
